import { createSlice } from "@reduxjs/toolkit";
const initialState ={
    loading:false
}
const loaderSlice = createSlice({
    name:"loader",
    initialState,
    reducers:{
        isLoading:(state,action) => {
            state.loading = action.payload
        }
    }
});

export const {isLoading} = loaderSlice.actions;
export default loaderSlice.reducer