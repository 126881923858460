import React from 'react'
import "./Loader.scss"
const Loader = () => {
  return (
    <div className="loader-overlay">
    <div className="loader-container"></div>
    <p>Creating New Profile...</p>
   </div>
  )
}

export default Loader