import { configureStore } from "@reduxjs/toolkit";


import userReducer from './userReducer/userSlice';
import chatsReducer from './chatsReducer/chatsSlice';
import conversationReducer from "./conversationReducer/conversationSlice";
import requestMessageReducer from './requestReducer/requestMSlice'
import loaderReducer from "./loaderReducer/loaderSlice";

export const store = configureStore({
    reducer:{
        user:userReducer,
        chats:chatsReducer,
        conversation:conversationReducer,
        requestM:requestMessageReducer,
        loader:loaderReducer
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})