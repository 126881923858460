import React from 'react'
import './Spinner.scss'
const Spinner = () => {
  return (
   <div className="spinner-overlay">
    <div className="spinner-container"></div>
   </div>
  )
}

export default Spinner